// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-actualizacion-js": () => import("./../../../src/pages/actualizacion.js" /* webpackChunkName: "component---src-pages-actualizacion-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-integrantes-js": () => import("./../../../src/pages/integrantes.js" /* webpackChunkName: "component---src-pages-integrantes-js" */),
  "component---src-pages-mapa-js": () => import("./../../../src/pages/mapa.js" /* webpackChunkName: "component---src-pages-mapa-js" */),
  "component---src-pages-natividad-js": () => import("./../../../src/pages/natividad.js" /* webpackChunkName: "component---src-pages-natividad-js" */),
  "component---src-pages-objetivo-js": () => import("./../../../src/pages/objetivo.js" /* webpackChunkName: "component---src-pages-objetivo-js" */),
  "component---src-pages-publicaciones-js": () => import("./../../../src/pages/publicaciones.js" /* webpackChunkName: "component---src-pages-publicaciones-js" */)
}

